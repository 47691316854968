import React, { useState, useEffect } from 'react';
// import { navigate } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';

import PotockLink, {
  playIntro,
  playOutro,
  playOverlay,
} from '../components/PotockLink';

import Layout from '../components/Layout';

// import LocaleContext from '../context/Locale';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';
import FullScreenContainer from '../components/FullScreenContainer';

import RotateDevice from '../components/RotateDevice';

import gsap from 'gsap';
import SplitText from 'gsap/SplitText';

export const NEW_RECORD_PATH = '/sklep/plyty/plyta-potock-z-pradem';
export const NEW_RECORD_NAME = 'Z&nbsp;PRĄDEM';

// Styled Components
// Make shadow like in cover
const Shadow = styled.div`
  > h1,
  > h2 {
    text-shadow: 4px 0px 4px rgba(0, 0, 0, 0.32);
    line-height: 2.5rem;
  }

  a.btn-cta {
    box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.32);

    &:hover {
      box-shadow: none;
    }
  }
`;

const ArtDirectedBackground = ({ className, onBackgroundLoaded, children }) => {
  const { coverImage } = useStaticQuery(
    graphql`
      query {
        coverImage: file(relativePath: { eq: "cover.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const sources = [coverImage.childImageSharp.fluid];

  return (
    <BackgroundImage
      Tag="section"
      id="cover-image"
      className={className + ' full-screen w-full'}
      style={{
        paddingTop: '4rem',
      }}
      fluid={sources}
      onLoad={onBackgroundLoaded}
    >
      {children}
    </BackgroundImage>
  );
};

const SectionWithCoverBackground = styled(ArtDirectedBackground)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: 100%;
`;

// Main Component
function IndexPage() {
  const [isActivatedAnimation, activateAnimation] = useState(false);
  const [isBackgroundLoaded, setBackgroundLoaded] = useState(false);

  // Run first overlay, only once on start
  useEffect(() => {
    playOverlay(0);
    if (isBackgroundLoaded) {
      gsap.delayedCall(0, playIntro);
      gsap.delayedCall(1.5, playOutro);
      setTimeout(() => {
        activateAnimation(true);
      }, 1500);
    }
  }, [isBackgroundLoaded]);

  // Init animation once
  useEffect(() => {
    if (typeof document !== 'undefined') {
      const t1 = gsap.timeline();
      t1.set('.animated-text', { opacity: 0 });
      t1.set('.animated-btn', { opacity: 0 });
      t1.set('.full-screen-container', { display: 'block' });
    }
  }, []);

  // Run animation
  useEffect(() => {
    if (
      isActivatedAnimation &&
      isBackgroundLoaded &&
      typeof document !== 'undefined'
    ) {
      const anim = gsap.timeline({ delay: 1 });
      anim.set('.full-screen-container', { opacity: 1 });

      anim.set('.animated-text', { opacity: 1 });
      // anim.set(".animated-btn", { opacity: 1 });

      // Get all animated texts
      const texts = document.querySelectorAll('.animated-text');

      // Iterate over all animated texts
      texts.forEach(text => {
        const st = new SplitText(text, { type: 'lines,words,chars' });
        anim.from(st.chars, {
          stagger: 0.02,
          opacity: 0,
          skewX: -20,
          scaleY: 0,
        });
      });

      // Get all animated buttons
      anim.to('.animated-btn', { stagger: 0.5, opacity: 1 });
    }
  }, [isActivatedAnimation]);

  const recordName = `"${NEW_RECORD_NAME}"`;

  return (
    <Layout fullPage={true} potockAnimation={true}>
      <SectionWithCoverBackground
        className="main-page-screen bg-position-center"
        onBackgroundLoaded={() => setBackgroundLoaded(true)}
      >
        <FullScreenContainer className="opacity-0">
          <Shadow className="flex-grow flex items-center w-auto justify-between flex-col">
            <h1 className="uppercase animated-text">
              Płyta <span dangerouslySetInnerHTML={{ __html: recordName }} />
            </h1>
            <h2 className="animated-text">tylko w naszym sklepie!</h2>
            <div className="flex">
              {/*<a*/}
              {/*  className="animated-btn opacity-0 btn btn-cta btn-sm border lg:border-0 border-white mt-6 font-bold text-white mr-4 text-sm"*/}
              {/*  href="https://www.facebook.com/p0t0ck/videos/288674532284752"*/}
              {/*  target="_blank"*/}
              {/*  style={{*/}
              {/*    backgroundColor: 'rgba(0, 0, 0, 0.1)',*/}
              {/*  }}*/}
              {/*>*/}
              {/*  posłuchaj*/}
              {/*</a>*/}

              <PotockLink
                className="animated-btn opacity-0 btn-cta mt-6 font-bold text-sm"
                to={NEW_RECORD_PATH}
              >
                kup teraz
              </PotockLink>
            </div>
          </Shadow>
        </FullScreenContainer>

        <RotateDevice />
      </SectionWithCoverBackground>
    </Layout>
  );
}

export default IndexPage;
